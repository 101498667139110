import { RsvpResponse } from "./Rsvp.styles"
import { Content, Heading} from "../../components";

export const RsvpContainer = () => {
    return (
        <Content>
            <br/>
            <RsvpResponse>
                RSVPs are now closed <br/> 
                Thank you for your response! <br/>                
            </RsvpResponse>
        </Content>
        );
}

export default RsvpContainer;